import React, { useEffect, useState } from "react";
import { useAuth } from "../../common/context";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { getDashboard } from "../../common/api";
import { CircularProgress, Grid } from "@mui/material";
import { Container } from "../../ui";
import { Box } from "@mui/system";
import { useMediaQuery, Theme } from "@mui/material";
import { LogoSecondary } from "../../common/assets";

const Investment: React.FC = () => {
  const { user } = useAuth();
  const [dashboard, setDashboard] = useState<any>(null);
  const [loaded, setLoaded] = useState(false);
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (user) {
      getDashboard(
        user?.["custom:clientId"],
        user?.["custom:clientSecret"],
        "investor"
      ).then((res) => {
        try {
          const dashboard = JSON.parse(res.data);
          if (dashboard.embedUrl !== undefined) {
            setDashboard(JSON.parse(res.data));
          } else {
            setDashboard(null);
          }
        } catch (error) {
          setDashboard(null);
        }
        return res.data;
      });
    }
  }, [user]);

  return (
    <Container maxWidth="95" marginTop="10px">
      <Box
        height="95vh"
        sx={{ "&>div": { height: "100%" } }}
        style={{ display: loaded ? "block" : "none" }}
      >
        {mobile ? (
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: dashboard?.embedUrl[0].reportId,
              embedUrl: dashboard?.embedUrl[0].embedUrl,
              accessToken: dashboard?.accessToken,
              tokenType: models.TokenType.Embed,
              settings: {
                zoomLevel: 0.98,
                filterPaneEnabled: true,
                layoutType: models.LayoutType.MobilePortrait,
                panes: {
                  bookmarks: {
                    visible: false,
                  },
                  fields: {
                    expanded: false,
                  },
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                  pageNavigation: {
                    visible: false,
                  },
                  selection: {
                    visible: true,
                  },
                  syncSlicers: {
                    visible: true,
                  },
                  visualizations: {
                    expanded: false,
                  },
                },
              },
              pageView: "oneColumn",
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  function () {
                    console.log("Report loaded");
                  },
                ],
                [
                  "rendered",
                  function () {
                    console.log("Report rendered");
                  },
                ],
              ])
            }
          />
        ) : (
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: dashboard?.embedUrl[0].reportId,
              embedUrl: dashboard?.embedUrl[0].embedUrl,
              accessToken: dashboard?.accessToken,
              tokenType: models.TokenType.Embed,
              pageView: "fitToWidth",
              settings: {
                zoomLevel: 0.98,
                filterPaneEnabled: true,
                navContentPaneEnabled: true,
                layoutType: models.LayoutType.Custom,
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                panes: {
                  bookmarks: {
                    visible: false,
                  },
                  fields: {
                    expanded: false,
                  },
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                  pageNavigation: {
                    visible: false,
                  },
                  selection: {
                    visible: true,
                  },
                  syncSlicers: {
                    visible: true,
                  },
                  visualizations: {
                    expanded: false,
                  },
                },
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  function () {
                    console.log("Report loaded");
                  },
                ],
                [
                  "rendered",
                  function () {
                    setLoaded(true);
                  },
                ],
              ])
            }
          />
        )}
      </Box>
      <Box height="95vh" style={{ display: !loaded ? "block" : "none" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="50%"
        >
          <LogoSecondary width="10rem" height="10rem" />
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="top"
          width="100%"
          height="50%"
        >
          <CircularProgress size="7rem" />
        </Grid>
      </Box>
    </Container>
  );
};

export default Investment;
//color="#00AFC8"
