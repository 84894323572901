import { useEffect, useState } from 'react'
import { isEmailValid } from '../../common/helpers'
import { Location, useLocation, useNavigate } from 'react-router-dom'
import { FormikHelpers, replace } from 'formik'
import { useAuth } from '../../common/context'

interface Values {
  email: string
  password: string
}

interface State {
  error?: string
  success?: string
  preferredMfa?: string
}

export const initialValues: Values = {
  email: '',
  password: ''
}

export const validate = (values: Values) => {
  const errors: Partial<Values> = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!isEmailValid(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.password) {
    errors.password = 'Required'
  }

  return errors
}

type LocationState = {
  from: Location
}
const useLogin = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const mfaPath = { pathname: '/mfa' }

  const { login, getPreferredMfa } = useAuth()

  const [state, setState] = useState<State>({
    error: undefined,
    success: undefined,
    preferredMfa: undefined
  })

  const onSubmit = (values: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
    const { email, password } = values
    login(email, password, async (authSuccess, response) => {
      if (authSuccess) {
        setState({ ...state })
        resetForm()
        setSubmitting(false)
        if (response === "SOFTWARE_TOKEN_MFA") {
          navigate(mfaPath, { replace: true })
        } else {
          const preferredMfa = await getPreferredMfa()
          // opens modal for setup mfa
          setState({ ...state, preferredMfa })
        }
      } else {
        setState({
          error: response,
          success: undefined
        })
        setSubmitting(false)
      }
    })
  }
  return { state, setState, onSubmit }
}

export default useLogin
