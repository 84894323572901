import { useEffect } from "react";
import { useAuth } from "../../common/context";
import { useNavigate } from "react-router-dom";

const Redirect: React.FC = () => {
  const { groups } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (groups) {
      navigate(`/${groups[0]}`);
    }
  }, [groups]);

  return <div></div>;
};

export default Redirect;
