import { LoadingButton } from '@mui/lab'
import { Link, TextField, Typography, Alert } from '@mui/material'
import React, { useState } from 'react'
import { useAuth } from '../../common/context/auth'
import * as S from './styles'
import { UserAttributes } from '../../common/static'
import { Modal } from '../../ui'
import ConfirmMfa from './ConfirmMfa'

export type MfaVerificationParams = {
  user: UserAttributes | null
  code: string
}

interface State {
  error?: string
  success?: string
}

interface MfaProps {
  open: boolean
}

const Mfa: React.FC<MfaProps> = ({ open }: MfaProps) => {
  const [code, setCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [btnDisable, setBtnDisable] = useState(true)
  const [state, setState] = useState<State>({
    error: undefined,
    success: undefined
  })
  const { tempUser, verifyTOTPToken } = useAuth()

  const [modals, setModals] = useState({
    confirmMfa: false
  })

  const handleClose = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: false }))
  }

  const handleOpen = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: true }))
  }

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    const regex = /^([0-9]){0,6}$/i
    if (value === '' || regex.test(value)) {
      setCode(value)
      setBtnDisable(false)
    }
  }

  const handleValidateCode = async (): Promise<void> => {
    try {
      if (isLoading || state.error || !tempUser) return

      setIsLoading(true)
      const response = await verifyTOTPToken(code)
      if (response) {
        handleOpen('confirmMfa')
      }
    } catch (error) {
      setState({ error: (error as Error).message })
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal open={open} title="Enter verification code">
        {state.error && <Alert severity="error">{state.error}</Alert>}
        <S.Row>
          <Typography variant="body2">
            Open your{' '}
            <Link
              component="a"
              href="https://support.google.com/accounts/answer/1066447"
              target="_blank"
              rel="noopener"
            >
              Two-factor authentication app
            </Link>{' '}
            and enter the 6 digit code to verify.
          </Typography>
        </S.Row>
        <S.Row>
          <S.InputLabel>Verification code *</S.InputLabel>
          <TextField
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            placeholder="Enter the 6 digit code"
            fullWidth
            value={code}
            onChange={handleCodeChange}
          />
        </S.Row>
        <S.Row>
          <LoadingButton sx={{color: 'white'}} variant="contained" size="large" onClick={handleValidateCode} disabled={btnDisable} fullWidth>
            Verify
          </LoadingButton>
        </S.Row>
      </Modal>
      {modals.confirmMfa && <ConfirmMfa open={modals.confirmMfa} onClose={() => handleClose('confirmMfa')} />}
    </>
  )
}

export default Mfa
