import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../common/context";

type AuthType = "guest" | "protected";

interface RequireAuthProps {
  children: JSX.Element;
  authType: AuthType;
  group?: string;
}

const RequireAuth: React.FC<RequireAuthProps> = ({
  authType,
  children,
  group,
}: RequireAuthProps) => {
  const { loading, user, groups } = useAuth();
  const location = useLocation();

  const proceed = (): boolean => {
    let authorized = true;
    if (group) {
      authorized = groups?.includes(group) ?? false;
    }
    if (authorized) {
      switch (authType) {
        case "protected":
          return user ? true : false;

        case "guest":
          return user ? false : true;

        default:
          return true;
      }
    } else {
      return authorized;
    }
  };

  if (loading) {
    return <></>;
  } else if (proceed()) {
    return children;
  } else if (authType === "guest") {
    return <Navigate to="/" state={{ from: location }} />;
  } else {
    return <Navigate to="/login" state={{ from: location }} />;
  }
};

export default RequireAuth;
