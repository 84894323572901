import httpClient from "../../axios";

export const getDashboard = async (
  clientId: string,
  clientSecret: string,
  reportType: string
) =>
  httpClient.get(
    `/?id=${clientId}&key=${clientSecret}&reportType=${reportType}`
  );
