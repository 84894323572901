import { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoUser, SignUpParams } from "@aws-amplify/auth";
import {
  convertUserAttributesListToLabels,
  UserAttributes,
} from "../../static";
import httpClient from "../../axios/config";
import { createUser } from "../../api";
import axios from "axios";
import { history } from "../../../routes/history";
import { alertDialog, alertNotification } from "../../../ui";
import { io, Socket } from "socket.io-client";

interface Headers {
  [key: string]: string;
}

export interface State {
  isLoading: boolean;
  user: UserAttributes | null;
  tempUser: CognitoUser | null;
  groups: string[] | null;
  socket: Socket | null;
}

export type AuthResultCallback = (
  authSuccess: boolean,
  response: string
) => void;

export interface AuthContext {
  user: UserAttributes | null;
  tempUser: CognitoUser | null;
  groups: string[] | null;
  loading: boolean;
  setUser: React.Dispatch<React.SetStateAction<State>>;
  socket: Socket | null;
  signup: (params: SignUpParams, cb: AuthResultCallback) => void;
  login: (email: string, password: string, cb: AuthResultCallback) => void;
  logout: () => void;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (
    username: string,
    code: string,
    password: string
  ) => Promise<void>;
  verifyEmail: (username: string, code: string) => Promise<void>;
  resendEmail: (username: string) => Promise<void>;
  setupTOTP: () => Promise<string>;
  getPreferredMfa: () => Promise<string>;
  verifyTOTPToken: (challengeAnswer: string) => Promise<string>;
  mfaLogin: (
    tempUser: CognitoUser,
    challengeAnswer: string
  ) => Promise<boolean>;
  getPreferredMFALogin: (tempUser: CognitoUser) => Promise<string>;
}

const useProvideAuth = (): AuthContext => {
  const [state, setState] = useState<State>({
    isLoading: true,
    user: null,
    tempUser: null,
    groups: null,
    socket: null,
  });
  const lastRequest = useRef({
    time: new Date().getTime(),
    location: history.location.pathname,
  });

  const setUser = (user: CognitoUser) => {
    user.getUserAttributes((_err, result) => {
      if (result) {
        const userAttributes = convertUserAttributesListToLabels(result);
        const url = process.env.REACT_APP_SOCKET_URL as string;
        const idToken = user.getSignInUserSession()?.getIdToken();
        const token = idToken?.getJwtToken();
        const socket = io(url, {
          transports: ["polling"],
          query: { token },
        });
        setState({
          user: userAttributes,
          groups: idToken?.payload["cognito:groups"] as string[],
          isLoading: false,
          socket,
          tempUser: user,
        });
      }
    });
  };

  const setTempUser = (user: CognitoUser) => {
    setState({
      user: null,
      isLoading: false,
      socket: null,
      tempUser: user,
      groups: null,
    });
  };

  const getAuthenticatedUser = async () => {
    let loggedUser: CognitoUser | null;

    try {
      loggedUser = await Auth.currentAuthenticatedUser();
    } catch (error) {
      loggedUser = null;
    }

    return loggedUser;
  };

  const jwtInterceptor = () => {
    httpClient.interceptors.request.use(async (request) => {
      const loggedUser = await getAuthenticatedUser();

      if (loggedUser) {
        // force user to refresh if they stay on the same page for more than 20 min without interacting with the server
        if (
          Math.abs(new Date().getTime() - lastRequest.current.time) /
            (60 * 1000) >
            20 &&
          history.location.pathname === lastRequest.current.location
        ) {
          alertDialog(
            "Your time has expired",
            "Please refresh the page for any changes that might have occurred.",
            false
          );
          // cancel request
          return {
            ...request,
            cancelToken: new axios.CancelToken((cancel) =>
              cancel("Cancel repeated request")
            ),
          };
        }

        lastRequest.current.time = new Date().getTime();
        lastRequest.current.location = history.location.pathname;
        const token = String(
          loggedUser.getSignInUserSession()?.getIdToken().getJwtToken()
        );
        if (token && request && request.headers) {
          (request.headers.common as unknown as Headers)[
            "Authorization"
          ] = `Bearer ${token}`;
        }
      }

      return request;
    });
  };

  const getCurrentAuthenticatedUser = async () => {
    try {
      const loggedUser: CognitoUser = await Auth.currentAuthenticatedUser();
      setUser(loggedUser);
    } catch {
      setState({ ...state, user: null, isLoading: false });
    }
  };

  useEffect(() => {
    getCurrentAuthenticatedUser();
    jwtInterceptor();
  }, []);

  const login = async (
    email: string,
    password: string,
    cb: AuthResultCallback
  ) => {
    try {
      const user: CognitoUser = await Auth.signIn({
        username: email,
        password,
      });
      setTempUser(user);
      cb(true, user.challengeName || "NOMFA");
    } catch (error) {
      console.log("error");
      if (axios.isAxiosError(error)) {
        // failed to create user
        Auth.signOut();
        cb(false, "Something is wrong, please try again later");
      }
      cb(false, (error as Error).message);
    }
  };

  const signup = async (params: SignUpParams, cb: AuthResultCallback) => {
    try {
      const response = await Auth.signUp(params);
      cb(true, response.user.getUsername());
    } catch (error) {
      cb(false, (error as Error).message);
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      state.socket?.disconnect();
      setState({
        socket: null,
        isLoading: false,
        user: null,
        tempUser: null,
        groups: null,
      });
    } catch (err) {
      alertNotification();
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await Auth.forgotPassword(email);
    } catch (err) {
      // email might not be registered.. do not display any error to the user
    }
  };

  const resetPassword = async (
    username: string,
    code: string,
    password: string
  ) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
    } catch (err) {
      throw new Error((err as Error).message);
    }
  };

  const verifyEmail = async (username: string, code: string) => {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (err) {
      throw new Error((err as Error).message);
    }
  };

  const resendEmail = async (username: string) => {
    try {
      await Auth.resendSignUp(username);
    } catch (err) {
      throw new Error((err as Error).message);
    }
  };

  const mfaLogin = async (
    tempUser: CognitoUser,
    challengeAnswer: string
  ): Promise<boolean> => {
    try {
      const response = await Auth.confirmSignIn(
        tempUser,
        challengeAnswer,
        "SOFTWARE_TOKEN_MFA"
      );
      if (response) {
        // return true
        setUser(tempUser);
      }
      return false;
    } catch (err) {
      throw new Error((err as Error).message);
    }
  };

  const getPreferredMfa = async (): Promise<string> => {
    try {
      const loggedUser: CognitoUser = await Auth.currentAuthenticatedUser();
      return await Auth.getPreferredMFA(loggedUser, {
        bypassCache: false,
      });
    } catch (err) {
      throw new Error((err as Error).message);
    }
  };

  const getPreferredMFALogin = async (
    tempUser: CognitoUser
  ): Promise<string> => {
    try {
      return await Auth.getPreferredMFA(tempUser, {
        bypassCache: false,
      });
    } catch (err) {
      throw new Error((err as Error).message);
    }
  };

  const setupTOTP = async (): Promise<string> => {
    let response: string;
    try {
      const loggedUser: CognitoUser = await Auth.currentAuthenticatedUser();
      response = await Auth.setupTOTP(loggedUser);
    } catch (err) {
      throw new Error((err as Error).message);
    }
    return response;
  };

  const verifyTOTPToken = async (challengeAnswer: string): Promise<string> => {
    try {
      const loggedUser: CognitoUser = await Auth.currentAuthenticatedUser();
      const response = await Auth.verifyTotpToken(loggedUser, challengeAnswer);
      if (!response) {
        throw new Error("Token unverified.");
      }
      if (response) {
        setUser(loggedUser);
      }
      return await Auth.setPreferredMFA(loggedUser, "TOTP");
    } catch (err) {
      console.log((err as Error).message);
      throw new Error("Token unverified.");
    }
  };

  return {
    user: state.user,
    tempUser: state.tempUser,
    loading: state.isLoading,
    setUser: setState,
    groups: state.groups,
    socket: state.socket,
    signup,
    login,
    logout,
    forgotPassword,
    resetPassword,
    verifyEmail,
    resendEmail,
    setupTOTP,
    verifyTOTPToken,
    getPreferredMfa,
    mfaLogin,
    getPreferredMFALogin,
  };
};

export default useProvideAuth;
