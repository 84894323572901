import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { Modal } from '../../../ui'

interface MessageSentProps {
  open: boolean
  handleClose: (modal: string) => void
}

const MessageSent: React.FC<MessageSentProps> = ({ open, handleClose }: MessageSentProps) => {
  return (
    <Modal open={open} title="You will soon receive an email if a user with this email address was found.">
      <Stack spacing={2}>
        <Typography variant="caption">
          If you can&apos;t see it in your Inbox, please check your spam/junk folder.
        </Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button color="primary" onClick={() => handleClose('messageSent')}>
            Close
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default MessageSent
