import { CssBaseline, ThemeProvider } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { HashRouter, Outlet, Route, Routes } from "react-router-dom";
import { AppContextProvider } from "../common/context";
import {
  Login,
  LoginMfa,
  Navbar,
  PageNotFound,
  Portfolio,
  Research,
  ResetPassword,
} from "../pages";
import Investment from "../pages/Investment/Investment";
import { AlertDialog, AlertNotification, theme } from "../ui";
import RequireAuth from "./RequireAuth";
import Redirect from "../pages/Redirect/Redirect";

const AppRoutes: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AlertDialog />
      <AlertNotification />
      <AppContextProvider>
        <HashRouter>
          <Routes>
            <Route
              element={
                <Box flex="1 0 auto">
                  <Navbar />
                  <Outlet />
                </Box>
              }
            >
              <Route
                path="/mfa"
                element={
                  <RequireAuth authType="guest">
                    <LoginMfa />
                  </RequireAuth>
                }
              />
              <Route
                index
                path="/"
                element={
                  <RequireAuth authType="protected">
                    <Redirect />
                  </RequireAuth>
                }
              />
              <Route
                index
                path="/investment"
                element={
                  <RequireAuth authType="protected" group="investment">
                    <Investment />
                  </RequireAuth>
                }
              />
              <Route
                path="/research"
                element={
                  <RequireAuth authType="protected" group="research">
                    <Research />
                  </RequireAuth>
                }
              />
              <Route
                path="/portfolio"
                element={
                  <RequireAuth authType="protected" group="portfolio">
                    <Portfolio />
                  </RequireAuth>
                }
              />
              <Route
                path="/login"
                element={
                  <RequireAuth authType="guest">
                    <Login />
                  </RequireAuth>
                }
              />
              <Route
                path="/reset-password"
                element={
                  <RequireAuth authType="guest">
                    <ResetPassword />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </HashRouter>
      </AppContextProvider>
    </ThemeProvider>
  );
};

export default AppRoutes;
