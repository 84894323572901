import { LoadingButton } from '@mui/lab'
import { Divider, Link, LinkProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import QRCodeCanvas from 'qrcode.react'

export const Row = styled('div')({
  marginBottom: '24px',
  textAlign: 'center',
})

export const ActionsRow = styled('div')({
  marginTop: 40,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

export const InputLabel = styled(Typography)({
  marginBottom: '12px',
  fontWeight: 600
})

export const Button = styled(LoadingButton)({
  width: '100%',
  borderRadius: '8px',
  background: '#00AFC8',
  fontWeight: 500,
  color: '#fff',
  fontStyle: 'normal',
  // marginLeft: '12px'
})

export const Splitter = styled(Divider)({
  margin: '32px 0'
})

interface StyledLinkProps extends LinkProps {
  component?: string
}

export const StyledLink = styled(Link)<StyledLinkProps>({
  fontWeight: 400,
  color: '#009DB6',
  cursor: 'pointer'
})
export const StyledLinkEmail = styled(Link)<StyledLinkProps>({
  fontWeight: 400,
  color: '#009DB6',
  cursor: 'pointer'
})

export const Step = styled('li')({})

export const StepsList = styled('ol')({
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '115%',
  textAlign: 'left',
  marginTop: '22px',
  listStyleType: 'decimal',
  listStylePosition: 'inside'
})

export const CustomQRCode = styled(QRCodeCanvas)({
  margin: '30px',
  marginLeft: '124px'
})
