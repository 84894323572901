import { Paper, PaperProps, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const Title = styled(Typography)({
  fontWeight: 600,
  marginBottom: '25px',
  fontStyle: "Inter"
})

interface StyledPaperProps extends PaperProps {
  paperPadding?: number | string
}

export const StyledPaper = styled(Paper, { shouldForwardProp: (prop) => prop !== 'paperPadding' })<StyledPaperProps>(
  ({ paperPadding }) => ({
    borderRadius: 24,
    padding: paperPadding || '32px 25px'
  })
)
