import { Alert, Snackbar } from '@mui/material'
import React, { useState } from 'react'

interface State {
  message: string
  open: boolean
  severity: 'error' | 'success'
}

// this will be used to control the state inside AlertNotification
let setNotificationState: React.Dispatch<React.SetStateAction<State>>

const AlertNotification: React.FC = () => {
  const [state, setState] = useState<State>({
    message: '',
    open: false,
    severity: 'error'
  })

  setNotificationState = setState

  const { message, open, severity } = state

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{ bottom: '40px !important' }}
    >
      <Alert
        severity={severity}
        onClose={() => setState({ ...state, open: false })}
        elevation={6}
        sx={{ minWidth: 500 }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export const alertNotification = (message?: string, severity: 'error' | 'success' = 'error') => {
  setNotificationState({ message: message || 'Something went wrong. Please, try again later.', open: true, severity })
}

export default AlertNotification
