import React, { useState } from 'react'
import { Typography, Box } from '@mui/material'
import * as S from './styles'
import { Container } from '../../ui'
import { Formik, Form } from 'formik'
import { initialValues, validate } from './Mfa.State'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useAuth } from '../../common/context/auth'

interface State {
  error?: string
  success?: string
}


const LoginMfa: React.FC = () => {
    const [code, setCode] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<State>({
      error: undefined,
      success: undefined
    })
    const { tempUser, mfaLogin } = useAuth()
  
    const handleCodeChange = (value: string): void => {
      const regex = /^([0-9]){0,6}$/i
      if (value === '' || regex.test(value)) {
        setCode(value)
        // setBtnDisable(false)
      }
      if (value.length === 6) {
        handleValidateCode(value)
      }
    }
  
    const handleValidateCode = async (code: string): Promise<void> => {
      try {
        if (isLoading || state.error || !tempUser) return
        setIsLoading(true)
        await mfaLogin(tempUser, code)
      } catch (error) {
        setState({ error: (error as Error).message })
        setIsLoading(false)
      }
    }

    return (
        <>
        <Formik
          initialValues={initialValues}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={()=> {}}
        >
          {() => (
            <Form>
              <Container 
                title="Authenticate Your Identity" 
              >
                <S.Row>
                  <Typography variant="body2">Please enter the pin that has been sent to your <br/> connected email address.</Typography>
                </S.Row>
                <Box sx={{minHeight: '10vh'}}>
                  <MuiOtpInput 
                    length={6}
                    value={code}
                    onChange={handleCodeChange}
                  />
                </Box>
                <Typography variant="subtitle1" textAlign="center">
                  Having trouble with logging in?<br/> Contact our support at <S.StyledLinkEmail href="/signup">support@yaasdigital</S.StyledLinkEmail>
                </Typography>
              </Container>
            </Form>
          )}
        </Formik>
      </>
    )
}

export default LoginMfa