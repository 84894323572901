import { Typography } from '@mui/material'
import React from 'react'
// import { LogoSecondary } from 'src/common/assets'
import * as S from './styles'

const MobileDesktopNotification: React.FC = () => {
  return (
    <S.Container>
      {/* <LogoSecondary width={70} height={70} /> */}
      <Typography variant="h5" fontWeight={600} m="40px 0 16px">
        YaaS will be on mobile soon!
      </Typography>
      <Typography variant="h5" fontWeight={600} mb="40px">
        For now, <S.Subtext>please use desktop for the best experience.</S.Subtext>
      </Typography>
    </S.Container>
  )
}

export default MobileDesktopNotification
