import { CheckCircleOutlined } from '@mui/icons-material'
import { Box, Typography, DialogProps } from '@mui/material'
import React from 'react'
import { Modal } from '../../ui'

interface ConfirmMfaProps extends DialogProps {
  open: boolean
}

const ConfirmMfa: React.FC<ConfirmMfaProps> = ({ ...props }: ConfirmMfaProps) => {
  return (
    <Modal {...props} title="2-factor authentication enabled">
      <Box textAlign="center">
        <CheckCircleOutlined color="primary" sx={{ fontSize: '8rem' }} />
        <Typography variant="h6" fontWeight={400} padding="0 16px">
          2-factor authentication enabled successfully. You will need to provide the authentication code from next login
          onwards.
        </Typography>
      </Box>
    </Modal>
  )
}

export default ConfirmMfa
