import { CognitoUserAttribute } from 'amazon-cognito-identity-js'

export interface UserAttributes {
  sub: string
  email: string
  given_name: string
  family_name: string
  email_verified: string
  "custom:clientId": string,
  "custom:clientSecret": string,
}

export const convertUserAttributesListToLabels = (attributes: CognitoUserAttribute[]): UserAttributes =>
  attributes.reduce((obj, item) => Object.assign(obj, { [item.Name]: item.Value }), {} as UserAttributes)
