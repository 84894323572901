import * as React from "react";
import {
  AppBar,
  IconButton,
  Typography,
  Menu,
  Tooltip,
  MenuItem,
  useMediaQuery,
  Theme,
  Box,
  Link,
  Select,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";
import { KeyboardArrowDown, Menu as MenuIcon } from "@mui/icons-material";
import { LogoSecondary, LogoPrimary } from "../../common/assets";
import { useAuth } from "../../common/context";
import * as S from "./styles";
import { useLocation } from "react-router-dom";
import useNavbar from "./Navbar.state";

const pagesLoggedIn = [
  {
    link: "Investment",
    path: "/",
  },
  {
    link: "Research",
    path: "/research",
  },
  {
    link: "Portfolio",
    path: "/portfolio",
  },
];

const settings = [
  {
    item: "Profile",
    path: "/profile",
  },
];

const Navbar: React.FC = () => {
  const { user, logout, groups } = useAuth();
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const {
    anchorElNav,
    anchorElUser,
    handleOpenNavMenu,
    handleOpenUserMenu,
    handleCloseNavMenu,
    handleCloseUserMenu,
  } = useNavbar();

  const { pathname } = useLocation();

  return (
    <>
      <AppBar color="inherit">
        <S.NavToolbar>
          {user ? (
            <S.LogoContainer href="/" style={{ width: "36px" }}>
              <LogoSecondary width="100%" height="100%" />
            </S.LogoContainer>
          ) : (
            <S.LogoContainer href="/">
              <LogoPrimary width="100%" height="100%" />
            </S.LogoContainer>
          )}
          <S.NavLinksContainer
            sx={{ display: { xs: "none", lg: "inline-flex" } }}
          >
            {user ? (
              pagesLoggedIn
                .filter((page) => groups?.includes(page.link.toLowerCase()))
                .map((page) => (
                  <S.NavLink
                    key={page.link}
                    href={page.path}
                    target={user ? undefined : "_blank"}
                    rel={user ? undefined : "noopener"}
                    underline="none"
                    onClick={handleCloseNavMenu}
                    active={pathname === page.path}
                  >
                    {page.link}
                  </S.NavLink>
                ))
            ) : (
              <></>
            )}
          </S.NavLinksContainer>
          {user ? (
            <>
              <Tooltip title="Account">
                <Box sx={{ minWidth: 120 }}>
                  <S.AvatarIconButton>
                    <S.AvatarInitials alt="user-initials">
                      {/* T.U */}
                    </S.AvatarInitials>
                  </S.AvatarIconButton>
                  <Button
                    endIcon={<KeyboardArrowDown />}
                    sx={{
                      background: "transparent",
                      fontWeight: 400,
                      ":hover": { backgroundColor: "transparent" },
                    }}
                    onClick={handleOpenUserMenu}
                  >
                    {user.given_name}
                  </Button>
                </Box>
              </Tooltip>
              <Menu
                sx={{ mt: "48px" }}
                id="menu-account"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map(({ item, path }, i) => (
                  <MenuItem
                    key={i}
                    component={Link}
                    onClick={handleCloseUserMenu}
                    href={path}
                  >
                    <Typography>{item}</Typography>
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={() => {
                    logout();
                    handleCloseUserMenu();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : !mobile ? (
            <div>
              <S.AuthButton href="/login" size="small">
                Sign In
              </S.AuthButton>
              {/* <S.SignUpButton href="/signup" variant="contained" size="small">
                  Sign Up
                </S.SignUpButton> */}
            </div>
          ) : (
            <>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </>
          )}
        </S.NavToolbar>
      </AppBar>
      <Box height={75} />
    </>
  );
};

export default Navbar;
