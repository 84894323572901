import React from 'react'
import { createTheme, responsiveFontSizes, CircularProgress, LinkProps, ThemeOptions } from '@mui/material'
import type {} from '@mui/lab/themeAugmentation'
import { LinkProps as RouterLinkProps, Link as RouterLink } from 'react-router-dom'

// extends mui link to also be a react router link
const LinkBehavior = React.forwardRef<never, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />
  }
)

LinkBehavior.displayName = 'LinkBehavior'

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    card: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    modal: true
  }
}

declare module '@mui/material/styles' {
  interface PaletteColor {
    100?: string
    200?: string
    300?: string
    400?: string
    500?: string
    700?: string
    800?: string
    900?: string
    1000?: string
    light: string
    main: string
    dark: string
    contrastText: string
  }
  // allow configuration using `createTheme`
  interface SimplePaletteColorOptions {
    100?: string
    200?: string
    300?: string
    400?: string
    500?: string
    700?: string
    800?: string
    900?: string
    1000?: string
    light?: string
    main: string
    dark?: string
    background?: string
    text?: string
    info?: string
    contrastText?: string
  }
}

// set theme colors
let theme = createTheme({
  palette: {
    primary: {
      100: '#F1F8FA',
      200: '#E4F1F4',
      300: '#C5E2EA',
      400: '#A1D2DF',
      500: '#72C1D4',
      700: '#009CB2',
      800: '#00879A',
      900: '#006E7E',
      1000: '#004E59',
      main: '#00AFC8',
      dark: '#008699'
    },
    error: {
      main: '#D3080C',
      background: '#FFE6E6',
      text: '#990003'
    },
    info: {
      main: '#00AFC8',
      background: '#E4F1F4',
      text: '#004E59'
    },
    background: {
      default: '#FFFFFF',
    }
  }
})

theme = createTheme(theme, {
  palette: {
    mode: 'light'
  },
  shape: {
    borderRadius: 16
  },
  typography: {
    button: {
      textTransform: 'none',
      fontWeight: 600,
      font: 'inter',
      fontStyle: 'semi-bold'
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: LinkBehavior
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        sizeSmall: ({ ownerState }) => ({
          ...(ownerState.startIcon && {
            fontSize: '1rem'
          }),
          padding: '5px 15px'
        }),
        sizeMedium: {
          fontSize: '1.1rem'
        },
        sizeLarge: {
          fontSize: '1.1rem',
          padding: '12px 22px'
        },
        text: ({ ownerState }) => ({
          ...(ownerState.color === 'primary' && {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
            ':hover': {
              backgroundColor: theme.palette.primary.dark
            }
          }),
          ...(ownerState.size === 'medium' && {
            padding: '6px 16px'
          })
        }),
        contained: ({ ownerState }) => ({
          ...(ownerState.color === 'inherit' && {
            ':hover': {
              backgroundColor: theme.palette.grey[400]
            }
          })
        }),
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'modal' && {
            minHeight: '58px',
            border: '1px solid #000',
            borderRadius: '5px',
            color: theme.palette.text.primary,
            fontWeight: 500,
            justifyContent: 'space-between',
            paddingLeft: '18px',
            paddingRight: '18px',
            ':hover': {
              borderColor: theme.palette.primary.main
            },
            '& .MuiSvgIcon-root': {
              fontSize: '24px !important'
            }
          })
        })
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'card' && {
            boxShadow: '0px 9px 46px rgba(208, 126, 242, 0.1), 0px 11px 15px rgba(0, 0, 0, 0.12)'
          })
        })
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          '&.MuiBackdrop-invisible': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'medium' && {
            'input[type="text"]': {
              fontSize: '1.4rem',
              fontWeight: 600,
              paddingTop: '14px',
              paddingBottom: '14px'
            }
          })
        })
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        sizeSmall: {
          borderRadius: 6
        },
        root: {
          '&.Mui-error': {
            backgroundColor: '#FFE6E6'
          }
        }
      }
    },
    MuiLoadingButton: {
      defaultProps: {
        loadingIndicator: <CircularProgress color="primary" size={24} />
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow:
            "none"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          fontSize: '0.85rem'
        }
      }
    },
    MuiLink: {
      /**
       * in case you need the behavior of the link to be a normal anchor instead
       * of a react router Link, just set the component prop to anchor ('a' tag)
       *  */
      defaultProps: {
        component: LinkBehavior
      } as LinkProps
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%'
        },
        body: {
          height: '100%',
          '& > #root': {
            display: 'flex',
            flexDirection: 'column'
          }
        },
        a: {
          WebkitTapHighlightColor: 'rgba(0,0,0,0.3)'
        },
        div: {
          WebkitTapHighlightColor: 'rgba(0,0,0,0.3)'
        },
        '::-webkit-scrollbar': {
          width: 16
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,0.25)',
          borderRadius: 99,
          border: '5px solid transparent',
          backgroundClip: 'content-box'
        }
      }
    }
  }
} as ThemeOptions)

export default responsiveFontSizes(theme)
