export const calculatePasswordStrength = (
  password: string
): {
  error: string | null
  bar: { strengthColor: string; value: number }
} => {
  let conditionsFulfilled = 0
  conditionsFulfilled += password.length > 7 ? 1 : 0 // at least 8 chars
  conditionsFulfilled += /[a-z]/g.test(password) ? 1 : 0 // at least one lowercase
  conditionsFulfilled += /[A-Z]/g.test(password) ? 1 : 0 // at least one uppercase
  conditionsFulfilled += /[0-9]/g.test(password) ? 1 : 0 // at least one number
  conditionsFulfilled += /[\^$*.[\]{}()?\-"!@#%&/,><’:;|_~`]/g.test(password) ? 1 : 0 // at least one of  the special characters listed by cognito

  let error: string | null = null
  if (conditionsFulfilled < 5) {
    error =
      'Your password should have at least 8 characters, one uppercase, one lowercase, one number and one special character'
  }

  const bar = {
    strengthColor: 'disabled',
    value: 0
  }
  if (conditionsFulfilled > 0) {
    if (conditionsFulfilled > 2) {
      if (conditionsFulfilled > 4) {
        ;[bar.strengthColor, bar.value] = ['strong', 100]
      } else {
        ;[bar.strengthColor, bar.value] = ['medium', 66]
      }
    } else {
      ;[bar.strengthColor, bar.value] = ['weak', 33]
    }
  }

  return { error, bar }
}

export const isEmailValid = (email: string): boolean =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )
