import React, { useState } from 'react'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { Modal } from '../../../ui'
import { isEmailValid } from '../../../common/helpers'
import { useAuth } from '../../../common/context'
import { LoadingButton } from '@mui/lab'

interface ForgotPasswordProps {
  open: boolean
  handleOpen: (modal: string) => void
  handleClose: (modal: string) => void
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ open, handleOpen, handleClose }: ForgotPasswordProps) => {
  const [value, setValue] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)
  const [sending, setSending] = useState(false)

  const { forgotPassword } = useAuth()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    if (isEmailValid(newValue)) {
      setError(undefined)
    }
    setValue(newValue)
  }

  const handleOnClick = async () => {
    if (!isEmailValid(value)) {
      setError('Invalid Email Address')
    } else {
      setSending(true)
      await forgotPassword(value)
      setValue('')
      handleClose('forgotPassword')
      handleOpen('messageSent')
      setSending(false)
    }
  }

  return (
    <Modal open={open} title="Let's find your account">
      <Stack spacing={2}>
        <Typography>Fill out the following information to find your email.</Typography>
        <TextField
          value={value}
          onChange={handleChange}
          name="email"
          placeholder="Email"
          error={Boolean(error)}
          helperText={error}
          fullWidth
        />
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button color="primary" sx={{background: 'none'}} onClick={() => handleClose('forgotPassword')}>
            Cancel
          </Button>
          <LoadingButton variant="contained" color="primary" sx={{color: 'white'}} onClick={handleOnClick} loading={sending}>
            Send
          </LoadingButton>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default ForgotPassword
